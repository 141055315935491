import * as React from "react";
import { useState, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";

const leaflet = typeof window !== `undefined` ? require("react-leaflet") : null;
// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined";
// markup
const IndexPage = () => {
  const [toggle, setToggle] = useState(false);
  useEffect(() => {
    if (isBrowser) {
      var prevScrollpos = window.pageYOffset;
      window.onscroll = function () {
        var currentScrollPos = window.pageYOffset;
        if (prevScrollpos > currentScrollPos) {
          if (document.documentElement.clientWidth > 900) {
            document.getElementById("NAV_BAR").style.height = "auto";
            document.getElementById("SCROLL_TOP").style.display = "none";
          } else {
            document.getElementById("NAV_BAR").style.height = "3rem";
            document.getElementById("SCROLL_TOP").style.display = "none";
          }
        } else {
          document.getElementById("NAV_BAR").style.height = "0px";
          document.getElementById("SCROLL_TOP").style.display = "block";
        }
        prevScrollpos = currentScrollPos;
      };
    }
    return () => {};
  }, []);

  const cartStyle =
    "flex flex-col h-72 w-64 md:h-56 md:w-44 bg-white mt-5 mb-5  justify-start items-center shadow-lg rounded-2xl font-bold text-xs md:text-sm";
  const cartImageStyle = "flex h-64 w-64 md:h-44 md:w-44 rounded-t-2xl mb-3";

  return (
    <main
      onClick={() => {
        setToggle(false);
      }}
      className=""
    >
      {/* منو */}
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{
          zIndex: 10001,
        }}
        className={`md:hidden transition-all overflow-hidden fixed h-screen  bg-white flex-row shadow-2xl ${
          toggle ? "w-2/3" : "w-0"
        }`}
      >
        <div className="flex flex-col w-full h-screen ">
          <div className="flex justify-end w-full h-20">
            <div
              onClick={() => {
                setToggle(false);
              }}
              className="flex w-5 h-5 m-6 "
            ></div>
          </div>
          <div className="flex flex-row items-center justify-start w-full h-20 mr-6 text-base font-bold">
            <StaticImage className="w-16 h-16 " src="../images/logo.jpeg" />
            <div className="flex flex-col justify-center w-auto h-20 mr-3">
              <div>دکتر آیدا عسکریان </div>
              <div className="flex text-xs font-medium">
                پزشک پوست مو و زیبایی
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full h-auto pt-5">
            <a
              href="#HOME"
              onClick={() => {
                setToggle(false);
              }}
              className="flex flex-row items-center w-full h-12 pr-12"
            >
              <StaticImage
                className="w-4 h-4 ml-3"
                src="../images/home_icon.png"
              />
              <div>خانه</div>
            </a>
            <div className="w-2/3 h-0.5 bg-gray-300 mr-12"></div>
            <a
              href="#CONTACT"
              onClick={() => {
                setToggle(false);
              }}
              className="flex flex-row items-center w-full h-12 pr-12"
            >
              <StaticImage
                className="w-4 h-4 ml-3"
                src="../images/phone_icon.png"
              />
              <div>تماس با ما</div>
            </a>
            <div className="w-2/3 h-0.5 bg-gray-300 mr-12"></div>

            <a
              href="#SVC"
              onClick={() => {
                setToggle(false);
              }}
              className="flex flex-row items-center w-full h-12 pr-12"
            >
              <StaticImage
                className="w-4 h-4 ml-3"
                src="../images/medical_icon.png"
              />
              <div>خدمات</div>
            </a>
            <a
              href="#SVC_MICRO"
              onClick={() => {
                setToggle(false);
              }}
              className="flex flex-row items-center w-full h-12 pr-16"
            >
              <StaticImage
                className="w-4 h-4 ml-3"
                src="../images/medicine_icon.png"
              />
              <div>میکرونیدلینگ</div>
            </a>
            <a
              href="#SVC_JUSH"
              onClick={() => {
                setToggle(false);
              }}
              className="flex flex-row items-center w-full h-12 pr-16"
            >
              <StaticImage
                className="w-4 h-4 ml-3"
                src="../images/medical2_icon.png"
              />
              <div>درمان لک و جوش</div>
            </a>
            <a
              href="#SVC_PEEL"
              onClick={() => {
                setToggle(false);
              }}
              className="flex flex-row items-center w-full h-12 pr-16"
            >
              <StaticImage
                className="w-4 h-4 ml-3"
                src="../images/science_icon.png"
              />
              <div>لایه برداری</div>
            </a>
            <a
              href="#SVC_HAIR_FILLER"
              onClick={() => {
                setToggle(false);
              }}
              className="flex flex-row items-center w-full h-12 pr-16"
            >
              <StaticImage
                className="w-4 h-4 ml-3"
                src="../images/injection_icon.png"
              />
              <div>فیلر مو</div>
            </a>
            <a
              href="#SVC_LIPS"
              onClick={() => {
                setToggle(false);
              }}
              className="flex flex-row items-center w-full h-12 pr-16"
            >
              <StaticImage
                className="w-4 h-4 ml-3"
                src="../images/lips_icon.png"
              />
              <div>تزریق ژل لب</div>
            </a>
            <a
              href="#SVC_EYE_FILLER"
              onClick={() => {
                setToggle(false);
              }}
              className="flex flex-row items-center w-full h-12 pr-16"
            >
              <StaticImage
                className="w-4 h-4 ml-3"
                src="../images/drop_icon.png"
              />
              <div>فیلر چشم</div>
            </a>
            <a
              href="#SVC_BOTOX"
              onClick={() => {
                setToggle(false);
              }}
              className="flex flex-row items-center w-full h-12 pr-16"
            >
              <StaticImage
                className="w-4 h-4 ml-3"
                src="../images/botox_icon.png"
              />
              <div>بوتاکس</div>
            </a>
            <a
              href="#SVC_MESOTHERAPY"
              onClick={() => {
                setToggle(false);
              }}
              className="flex flex-row items-center w-full h-12 pr-16"
            >
              <StaticImage
                className="w-4 h-4 ml-3"
                src="../images/meso_icon.png"
              />
              <div>مزوتراپی</div>
            </a>
          </div>
        </div>
      </div>
      {/* صفحه معرفی دکتر */}
      <div id="HOME" className="md:min-h-screen">
        {/* NAV_BAR */}
        <div
          id="NAV_BAR"
          style={{ zIndex: 10001 }}
          className="fixed z-10 w-full overflow-hidden text-xs transition-all bg-white md:text-base "
        >
          <div className="flex flex-row items-center w-full h-auto ">
            <div
              onClick={(e) => {
                e.stopPropagation();
                setToggle((state) => !state);
              }}
              className="flex justify-start w-1/3 h-5 mt-3 mr-6 md:hidden"
            >
              <StaticImage
                className="w-6 h-6"
                src="../images/burger_icon.png"
              />
            </div>
            <p className="flex flex-row items-center justify-end w-3/4 mt-3 ml-5 text-base md:w-2/3 md:ml-20 ">
              <StaticImage
                className="w-5 h-5 ml-1 md:w-12 md:h-12 md:ml-3"
                src="../images/logo.jpeg"
              />
              <div>کلینیک دکتر آیدا عسکریان </div>
            </p>

            <section className="bigNav">
              <a href="#CONTACT">تماس با ما</a>
              <a href="#SVC">خدمات</a>
              <a href="#HOME">خانه</a>
            </section>
          </div>
        </div>
        <div className="flex flex-col w-full h-auto md:flex-row">
          <div className="mt-32 md:ml-10 md:mt-20 2xl:ml-24 2xl:mt-28">
            <StaticImage
              width="850"
              height="550"
              src="../images/doctor.png"
              alt="doctor"
              // layout="constrained"
            />
          </div>
          <div className="flex w-full h-auto md:absolute md:justify-end">
            <div className="flex flex-col w-full mt-10 text-right md:w-2/5 md:mt-56 mr-7 md:mr-28 2xl:mr-56 2xl:mt-64 mb-14">
              <div className="text-base font-bold md:text-3xl ">
                کلینیک پوست و مو
              </div>
              <p className="mt-5 ml-5 text-sm text-justify md:ml-0 md:mt-28 md:text-base">
                پوست یکی از مهم ترین بخش های بدن انسان است که هم از نظر زیبایی و
                هم حفظ سلامت سایر اعضای بدن اهمیت دارد. پوست مانع از عبور راحت
                تر میکروب ها و عفونت سایر اعضای بدن می شود. مراجعه به بهترین
                متخصص پوست و مو راهی برای درمان مشکلات پوستی است. از سوی دیگر،
                تزریقاتی زیبایی مانند تزریق ژل و بوتاکس باید حتماً توسط متخصص
                حرفه ای و با تجربه انجام شود زیرا زیباسازی و جوانسازی پوست از
                حساسیت زیادی برخوردار است و در صورتی که برند نامرغوبی استفاده
                شود و یا تزریق در محل نامناسبی صورت گیرد می تواند آسیب های جبران
                ناپذیری به همراه داشته باشد.
              </p>
              <div className="flex flex-row justify-start w-full px-2 pt-6 rtl ">
                {process.env.NODE_ENV === 'development' && <a href="https://shiva-medicare.vercel.app/register/61c5b4c009063ddd4e67df86" className="px-4 py-2 mx-2 text-white duration-300 bg-blue-500 rounded-lg hover:bg-blue-600">دریافت نوبت اینترنتی</a>}
                <a href="#CONTACT" className="px-4 py-2 mx-2 text-white duration-300 bg-gray-600 rounded-lg opacity-80 hover:bg-gray-700">راه های ارتباطی </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* دکمه page-up */}
      <a
        href="#HOME"
        id="SCROLL_TOP"
        style={{ zIndex: "10000" }}
        className="fixed hidden w-10 h-10 right-10 bottom-10"
      >
        <StaticImage
          // className="w-10 h-10"
          src="../images/up_icon.png"
          alt="face1"
        />
      </a>
      {/* اینستاگرام */}
      <ul
        className="max-h-screen mx-10 juicer-feed"
        style={{ direction: "rtl" }}
        data-feed-id="dr-aidaaskarian"
      >
        <h1 class="referral">
          <a href="https://www.juicer.io">Powered by Juicer.io</a>
        </h1>
      </ul>
      ;{/* صفحه خدمات و آدرس */}
      {/* خدمات */}
      <div className="flex flex-col w-full h-auto mt-12 md:mt-0 ">
        <div
          id="SVC"
          className="flex items-center justify-center w-full h-auto mt-3 mb-3 text-base font-bold md:text-2xl md:mt-0 md:mb-0 "
        >
          خدمات
        </div>
        <div className="flex flex-col items-center justify-center w-full h-auto mt-1 mb-5 bg-gray-100 md:flex-row md:space-x-20">
          <a href="#SVC_JUSH" className={cartStyle}>
            <StaticImage
              className={cartImageStyle}
              src="../images/face1.jpg"
              alt="face1"
            />
            <div className="flex items-center justify-center">
              <div>درمان لک و جای جوش</div>
            </div>
          </a>

          <a href="#SVC_MICRO" className={cartStyle}>
            <StaticImage
              className={cartImageStyle}
              src="../images/face2.png"
              alt="face2"
            />
            <div>میکرونیدلینگ</div>
          </a>

          <a href="#SVC_PEEL" className={cartStyle}>
            <StaticImage
              className={cartImageStyle}
              src="../images/face3.jpg"
              alt="face3"
            />
            <div>لایه برداری</div>
          </a>

          <a href="#SVC_HAIR_FILLER" className={cartStyle}>
            <StaticImage
              className={cartImageStyle}
              src="../images/face5.png"
              alt="face4"
            />
            <div>فیلر مو</div>
          </a>
        </div>
      </div>
      {/* آدرس */}
      <div id="CONTACT" className="flex flex-col w-full h-auto mt-8">
        <div className="flex items-center justify-center w-full h-auto text-base font-bold md:text-2xl md:mb-10 ">
          آدرس
        </div>
        <div className="flex flex-col items-center justify-center w-full h-auto md:flex-row md:h-52 md:space-x-20">
          <div className="flex items-center justify-center w-full h-auto mt-6 mb-6 md:h-48 md:w-1/2 md:ml-14">
            {isBrowser ? (
              <leaflet.MapContainer
                className="w-11/12 h-56"
                center={[29.6676769, 52.4768628]}
                zoom={14}
                scrollWheelZoom={false}
              >
                <leaflet.TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <leaflet.Marker position={[29.6676769, 52.4768628]}>
                  <leaflet.Popup>
                    Clinic is <br /> Here.
                  </leaflet.Popup>
                </leaflet.Marker>
              </leaflet.MapContainer>
            ) : (
              ""
            )}
          </div>
          <p className="flex flex-col items-start justify-center w-full h-auto mb-5 text-right md:h-48 md:w-1/2">
            <div className="flex flex-row w-full mt-2 ml-5">
              <StaticImage
                className="w-6 h-6 ml-3 mr-5 md:ml-5 md:mr-36 "
                src="../images/location_icon.png"
              />
              <div className="w-10/12">
                شیراز، میدان قصرالدشت به سمت پل معالی آباد، بلوار میرزای شیرازی
                شرقی، بین کوچه 20 و 22 ساختمان پزشکان شاهد، طبقه سوم، واحد 302
              </div>
            </div>
            {/* <div className="flex flex-row mt-2">
              <StaticImage
                className="w-6 h-6 ml-3 mr-5 md:ml-5 md:mr-36 "
                src="../images/telephone_icon.png"
              />
              <div style={{ direction: "ltr" }} href="tel:03136642629">
                031-36642629
              </div>
            </div> */}
            <div className="flex flex-row mt-2">
              <StaticImage
                className="w-6 h-6 ml-3 mr-5 md:ml-5 md:mr-36 "
                src="../images/app_icon.png"
              />
              <a
                style={{ direction: "ltr" }}
                href="https://wa.me/+989377318095"
              >
                0937 731 8095
              </a>
            </div>
            <div className="flex flex-row mt-2">
              <StaticImage
                className="w-6 h-6 ml-3 mr-5 md:ml-5 md:mr-36 "
                src="../images/instagram_icon.png"
              />
              <a
                style={{ direction: "ltr" }}
                href="https://www.instagram.com/dr.aidaaskarian"
              >
                @dr.aidaaskarian
              </a>
            </div>
          </p>
        </div>
      </div>
      {/* صفحه محصولات */}
      {/* درمان لک و جای جوش */}
      <div id="SVC_JUSH" className="flex w-full h-auto">
        <div className="flex flex-col w-full h-auto mt-20">
          <div className="flex flex-col items-center justify-center w-full h-auto md:flex-row ">
            <div className="flex items-center justify-center w-4/5 h-auto md:w-1/2">
              <StaticImage
                className="rounded-full "
                width="400"
                height="400"
                src="../images/face1.jpg"
                alt="darman jush"
              />
            </div>
            <p className="flex flex-col items-start justify-center ml-6 text-right md:w-1/2 md:mr-28 ">
              <div className="flex mt-6 mb-12 mr-10 text-base font-bold md:text-2xl md:mt-0 ">
                درمان لک و جای جوش
              </div>
              <p className="flex items-center justify-center h-auto mr-6 text-justify md:mr-0">
                پاکسازی پوست مهم ترین مرحله یک روتین پوستی است. در طول روز پوست
                در معرض آلودگی‌های بسیاری قرار می‌گیرد مانند آلودگی محیط، هوا،
                ذرات گرد و غبار و مواد آرایشی و … این آلودگی‌ها منافذ پوست را
                می‌بندند و اجازه تنفس به پوست نمی‌دهند. بهترین راه برای رهایی از
                این مشکل شستن و پاکسازی پوست است. پاکسازی پوست خودش شامل چند
                مرحله می‌شود که عبارتند از: پاکسازی پوست از مواد آرایشی و
                چربی‌ها با میسلار واتر یا شیر پاک کن و دستمال آرایشی و … شستن
                صورت با شوینده مناسب نوع پوست استفاده از ماسک‌های پاکسازی پوست
              </p>
            </p>
          </div>
        </div>
      </div>
      {/* خط */}
      <div className="flex h-0.5  w-4/5 bg-gray-300 mx-auto mt-8 items-center justify-center md:mt-6"></div>
      {/* فیلر مو */}
      <div id="SVC_HAIR_FILLER" className="flex w-full h-auto">
        <div className="flex flex-col items-center justify-center w-full h-auto mt-16 md:flex-row ">
          <div className="flex items-center justify-center w-4/5 h-auto md:w-1/2">
            <StaticImage
              className="rounded-full "
              width="400"
              height="400"
              src="../images/face5.png"
              alt="A dinosaur"
            />
          </div>
          <div className="flex flex-col items-start justify-end ml-6 text-right md:w-1/2 md:mr-0 md:ml-28 ">
            <div className="flex mt-6 mb-12 mr-10 text-base font-bold md:text-2xl md:mt-0">
              فیلر مو
            </div>
            <p className="flex items-center justify-center h-auto mr-6 text-justify md:mr-0 ">
              ماده فیلر توسط یک سرنگ با سوزن بسیار ریز به پوست سر تزریق می شود.
              بلافاصله پس از وارد شدن فیلر به زیر پوست مواد داخل آن آزاد و فعال
              می شوند و در مدت زمان کوتاهی سلول های پوست و مو را تقویت می کنند.
              این روش از مرگ سلولی جلوگیری می کند و سلامت ناحیه تزریق شده را
              تضمین می کند. به همین علت ریزش غیرطبیعی موها قطع و درمان می شود.
              فیلر موجب سلامت و شادابی موها می گردد. با احیای سلول های پوست سر
              گردش خون بهبود می یابد. بهبود گردش خون مواد لازم برای تغذیه
              فولیکول ها را تامین می کند. در نتیجه فولیکول ها تحریک شده و سرعت
              رشد آن ها افزایش می یابد.
            </p>
          </div>
        </div>
      </div>
      {/* خط */}
      <div className="flex h-0.5  w-4/5 bg-gray-300 mx-auto mt-8 items-center justify-center md:mt-6"></div>
      {/* میکرونیدلینک */}
      <div id="SVC_MICRO" className="flex w-full h-auto">
        <div className="flex flex-col items-center justify-center w-full h-auto mt-16 md:flex-row ">
          <div className="flex items-center justify-center w-4/5 h-auto md:w-1/2">
            <StaticImage
              className="rounded-full "
              width="400"
              height="400"
              src="../images/face2.png"
              alt="A dinosaur"
            />
          </div>
          <p className="flex flex-col ml-6 md:w-1/2 md:mr-28 ">
            <div className="flex mt-6 mb-12 mr-10 text-base font-bold md:text-2xl md:mt-0 ">
              میکرونیدلینک
            </div>
            <p className="flex items-center justify-center h-auto mr-6 text-justify t md:mr-0 ">
              یکی دیگر از روش های جوانسازی پوست میکرونیدلینک است. در این روش نیز
              سوزن هایی با ظرافت وارد لایه های پوستی شده و سلول های کلاژن ساز را
              برای ساخت پوست جدید تحریک می کند. این روش مقداری تهاجمی است و برای
              رفع مشکلات عمیق پوستی پیشنهاد می شود. میکرونیدلینگ را یکی از
              موثرترین روش ها برای از بین بردن جای جوش، اسکار و لک های پوستی می
              دانند.
            </p>
          </p>
        </div>
      </div>
      {/* خط */}
      <div className="flex h-0.5  w-4/5 bg-gray-300 mx-auto mt-8 items-center justify-center md:mt-6"></div>
      {/* لایه برداری */}
      <div id="SVC_PEEL" className="flex w-full h-auto">
        <div className="flex flex-col items-center justify-center w-full h-auto mt-16 md:flex-row ">
          <div className="flex items-center justify-center w-4/5 h-auto md:w-1/2">
            <StaticImage
              className="rounded-full "
              width="400"
              height="400"
              src="../images/face3.jpg"
              alt="A dinosaur"
            />
          </div>
          <div className="flex flex-col items-start justify-end ml-6 text-right md:w-1/2 md:mr-0 md:ml-28 ">
            <div className="flex mt-6 mb-12 mr-10 text-base font-bold md:text-2xl md:mt-0">
              لایه برداری
            </div>
            <p className="flex items-center justify-center h-auto mr-6 text-justify md:mr-0 ">
              لایه برداری پوست به فرآیندی گفته می‌شود که در آن سلول‌های مرده از
              سطح پوست برداشته می‌شوند. به طور کلی این کار به صورت فیزیکی یا
              شیمیایی انجام می‌شود. در روش‌های فیزیکی خانگی (مثل اسکراب‌ها) یا
              کلینیکی (مثل میکرودرم ابریژن) با شیوه‌های خاصی سطح پوست را ماساژ
              می‌دهیم تا سلول‌های مرده به شکل سایشی از روی پوست کنده شوند. در
              روش شیمیایی، به کمک لایه بردارهای شیمیایی اتصال سلول‌های مرده به
              پوست را شل می‌کنیم تا از سطح پوست جدا شوند.
            </p>
          </div>
        </div>
      </div>
      {/* خط */}
      <div className="flex h-0.5  w-4/5 bg-gray-300 mx-auto mt-8 items-center justify-center md:mt-6"></div>
      {/* تزریق ژل لب */}
      <div id="SVC_LIPS" className="flex w-full h-auto">
        <div className="flex flex-col items-center justify-center w-full h-auto mt-16 md:flex-row ">
          <div className="flex items-center justify-center w-4/5 h-auto md:w-1/2">
            <StaticImage
              className="rounded-full "
              width="400"
              height="400"
              src="../images/lips.jpg"
              alt="A dinosaur"
            />
          </div>
          <p className="flex flex-col ml-6 md:w-1/2 md:mr-28 ">
            <div className="flex mt-6 mb-12 mr-10 text-base font-bold md:text-2xl md:mt-0 ">
              تزریق ژل لب طبیعی
            </div>
            <p className="flex items-center justify-center h-auto mr-6 text-justify t md:mr-0 ">
              در تزریق ژل لب طبیعی، لب ها از حالت و فرم اصلی خارج نخواهند شد.
              نسبت بسیار خاصی برای آن که تزریق ژل لب، طبیعی به نظر برسد وجود
              دارد؛ به عبارت دیگر تزریق فیلر طبیعی نباید به صورتی باشد که برای
              عموم قابل تشخیص گردد و آنها را متوجه کند که شما تغییری در لب های
              خود ایجاد کرده اید و در عین حال باید زیبا تر از قبل از تزریق نیز
              باشد. لازم به ذکر است که انواع مختلف پرکننده های تزریقی و اینکه
              کدام یک را انتخاب کنید، تأثیر متفاوتی در نتایج تزریق ژل لب خواهد
              داشت. .مهم است که فیلر استفاده شده از قوام همان مخاط طبیعی لب
              برخوردار باشد. در غیر این صورت طبیعی به نظر نمی رسد.
            </p>
          </p>
        </div>
      </div>
      {/* خط */}
      <div className="flex h-0.5  w-4/5 bg-gray-300 mx-auto mt-8 items-center justify-center md:mt-6"></div>
      {/* فیلر چشم  */}
      <div id="SVC_EYE_FILLER" className="flex w-full h-auto">
        <div className="flex flex-col items-center justify-center w-full h-auto mt-16 md:flex-row ">
          <div className="flex items-center justify-center w-4/5 h-auto md:w-1/2">
            <StaticImage
              className="inline-block text-center rounded-full "
              width="400"
              height="400"
              src="../images/eyes.jpg"
              alt="A dinosaur"
            />
          </div>
          <div className="flex flex-col items-start justify-end ml-6 text-right md:w-1/2 md:mr-0 md:ml-28 ">
            <div className="flex mt-6 mb-12 mr-10 text-base font-bold md:text-2xl md:mt-0">
              فیلر چشم
            </div>
            <p className="flex items-center justify-center h-auto mr-6 text-justify md:mr-0 ">
              از فیلر زیر چشم برای پر شدن فرورفتگی ناحیه زیر چشم استفاده می شود.
              آنها باعث می شوند آن منطقه پرتر و روشن تر به نظر برسد و کاهش تیرگی
              های زیر چشم می تواند باعث شود شما سر حال تر به نظر برسید. اگر فکر
              می کنید چشمان شما خسته و فرسوده به نظر می رسد ، در صورتی که حتی
              استراحت خوبی دارید ، ممکن است فیلر زیر چشم گزینه ی مناسبی برای شما
              باشد
            </p>
          </div>
        </div>
      </div>
      {/* خط */}
      <div className="flex h-0.5  w-4/5 bg-gray-300 mx-auto mt-8 items-center justify-center md:mt-6"></div>
      {/* بوتاکس */}
      <div id="SVC_BOTOX" className="flex w-full h-auto">
        <div className="flex flex-col items-center justify-center w-full h-auto mt-16 md:flex-row ">
          <div className="flex items-center justify-center w-4/5 h-auto md:w-1/2">
            <StaticImage
              className="rounded-full "
              width="400"
              height="400"
              src="../images/Frowningline.jpg"
              alt="A dinosaur"
            />
          </div>
          <p className="flex flex-col ml-6 md:w-1/2 md:mr-28 ">
            <div className="flex mt-6 mb-12 mr-10 text-base font-bold md:text-2xl md:mt-0 ">
              بوتاکس{" "}
            </div>
            <p className="flex items-center justify-center h-auto mr-6 text-justify t md:mr-0 ">
              تزریق بوتاکس از جمله روش‌های کم‌تهاجمی جوانسازی پوست است و به
              عنوان روشی ایمن و موثر برای از بین بردن خطوط ظریف و همچنین چین و
              چروک‌های اطراف چشم بیشترین کاربرد را دارد. البته برای رفع خطوط
              بقیه‌ی بخش‌های صورت مثل خط اخم یا خطوط افقی پیشانی و خیلی موارد
              دیگر هم می‌توان از طریق از بوتاکس برای برطرف کردن خطوط استفاده
              کرد. هم چنین از بوتاکس جهت کاهش تعریق زیر بغل استفاده می شود که
            </p>
          </p>
        </div>
      </div>
      {/* خط */}
      <div className="flex h-0.5  w-4/5 bg-gray-300 mx-auto mt-8 items-center justify-center md:mt-6"></div>
      {/* مزوتراپی  */}
      <div id="SVC_MESOTHERAPY" className="flex w-full h-auto">
        <div className="flex flex-col items-center justify-center w-full h-auto mt-16 md:flex-row ">
          <div className="flex items-center justify-center w-4/5 h-auto md:w-1/2">
            <StaticImage
              className="inline-block text-center rounded-full "
              width="400"
              height="400"
              src="../images/mesotherapy.jpg"
              alt="A dinosaur"
            />
          </div>
          <div className="flex flex-col items-start justify-end ml-6 text-right md:w-1/2 md:mr-0 md:ml-28 ">
            <div className="flex mt-6 mb-12 mr-10 text-base font-bold md:text-2xl md:mt-0">
              مزوتراپی لاغری (موضعی)
            </div>
            <p className="flex items-center justify-center h-auto mr-6 text-justify md:mr-0 ">
              رفع سلولیت و لاغری موضعی، این روز ها تعداد زیادی از مراجعه کننده
              ها اکثرا مشکلاتشون چربی های موضعی هست که با ورزش و رژیم غذایی
              تغییر چندانی نمیکنه و اون موضع چربی حالت سفت و ثابتی به خودش گرفته
              و همچنین وجود سلولیت های جاهای مختلف بدن که برای مراجعه کنندگان
              بسیار آزاردهنده هست. مزوتراپی لاغری معمولا در هر موضع بین ۴-۵ جلسه
              با توجه به حجم توده چربی انجام میشه و به فاصله هر دو هفته یکبار
              تزریقات انجام میگیره .
            </p>
          </div>
        </div>
      </div>
      {/* footer */}
      <div className="flex w-full h-12"></div>
    </main>
  );
};

export default IndexPage;
